import React from 'react';
import PropTypes from 'prop-types';
import styles from './Logo.module.scss';

import LogoSvg from '../images/logo.svg';

export default function Logo({ style }) {
    return (
        <h1 className={styles.container} style={style}>
            <span style={{ position: 'absolute', left: -99999 }}>
                Damascus Grind
            </span>
            <LogoSvg className={styles.top} />
            <LogoSvg className={styles.bottom} />
        </h1>
    );
}

Logo.propTypes = {
    style: PropTypes.object,
};

Logo.defaultProps = {
    style: {},
};
