import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import WeaponCategoryButton from '~components/WeaponCategoryButton';
import styles from './WeaponCategoryContainer.module.scss';

export default function WeaponCategoryContainer() {
    const {
        allWeaponCategory: { nodes: weaponCategories },
    } = useStaticQuery(graphql`
        query WeaponCategoryQuery {
            allWeaponCategory {
                nodes {
                    name
                    slug
                }
            }
        }
    `);

    return (
        <div className={styles.container}>
            {weaponCategories.reduce((acc, category) => {
                if (!acc.names.includes(category.name)) {
                    acc.names.push(category.name);
                    acc.categories.push(category);
                }
                return acc;
            }, { names: [], categories: [] }).categories.map((category, i) => {
                return (
                    <WeaponCategoryButton {...category } key={i} />
                );
            })}
        </div>
    )
}

WeaponCategoryContainer.propTypes = {
    prop: PropTypes.any
}

WeaponCategoryContainer.defaultProps = {
    prop: null
}

