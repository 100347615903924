import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
// import { useSelector, useDispatch } from 'react-redux';

import storage, { STORAGE_KEY_WELCOME_MODAL } from '~utils/storage';
import Modal from '~components/modal/Modal';
import Container from '~components/Container';
import TipList from '~components/TipList';
import WeaponCategoryContainer from '~components/WeaponCategoryContainer';
import Logo from '~components/Logo';

export default function Page({ data }) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const siteTitle = get(data, 'site.siteMetadata.title');
    const siteDescription = get(data, 'site.siteMetadata.description');
    const tips = get(data, 'allTip.nodes', []);
    // const dispatch = useDispatch();
    // const [
    //     settings
    // ] = useSelector((state) => [
    //     state.settings
    // ]);

    useEffect(() => {
        if (!storage.get(STORAGE_KEY_WELCOME_MODAL)) {
            openModal();
        }
    }, []);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        storage.set(STORAGE_KEY_WELCOME_MODAL, true);
        setIsOpen(false);
    }

    function renderWelcomeModal() {
        if (!openModal) {
            return;
        }

        return (
            <Modal
                title="Welcome"
                subtitle={siteDescription}
                isOpen={modalIsOpen}
            >
                <p>
                    Please. For the love of all things holy, if you&apos;re
                    trying to get Damascus, make sure that you&apos;re unlocking
                    launcher camos alongside your primary weapons. If
                    you&apos;re only going for gold or platinum, you&apos;ll
                    eventually go for Damascus later. Trust me. Use your
                    launchers.
                </p>
                <p>
                    Also, buy Multiplayer. I&apos;m not being paid to say that
                    but unlocking Damascus with just Warzone is like watching
                    paint dry.
                </p>
                <button onClick={closeModal}>
                    <span>Okay, promise</span>
                </button>
            </Modal>
        );
    }

    return (
        <Fragment>
            <Helmet title={siteTitle} />
            <Container style={{ textAlign: 'center', marginBottom: '2em' }}>
                <Logo style={{ marginBottom: '0.25em' }} />
                <p style={{ color: '#fff', padding: '0 2em' }}>
                    {siteDescription}
                </p>
            </Container>

            {openModal && renderWelcomeModal()}

            <Container>
                <h3>General Tips</h3>
                <TipList tips={tips} />

                <h3>Choose a weapon class</h3>
                <WeaponCategoryContainer />
            </Container>
        </Fragment>
    );
}

Page.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};

export const pageQuery = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                title
                description
            }
        }
        allWeaponCategory {
            nodes {
                name
                slug
            }
        }
        allTip {
            nodes {
                text
            }
        }
    }
`;
